const state = {
  image: null,
  phase: null,
  project: null,
  isOpen: false,
  loading: false
}

const actions = {
  clear ({ commit }) {
    commit('clear')
  },
  toggleVisablity ({ commit }, val) {
    commit('toggleV', val)
  },
  save ({ commit }, payload) {
    commit('toggleLoading')
    commit('setPhase', payload.phase)
    commit('setProject', payload.project)
    commit('setImage', payload.image)
    commit('toggleLoading')
  }
}

const mutations = {
  clear (state) {
    state.image = null
    state.phase = null
    state.project = null
    state.isOpen = false
    state.loading = false
  },
  toggleV (state, val) {
    state.isOpen = val
  },
  toggleLoading (state) {
    state.loading = !state.loading
  },
  setPhase (state, phaseData) {
    state.phase = phaseData
  },
  setProject (state, data) {
    state.project = data
  },
  setImage (state, data) {
    state.image = data
  }
}

export const BottomSheetPms = {
  namespaced: true,
  state,
  actions,
  mutations
}
